import {transformPayload} from "../../helpers/transformPayload";
import {
  AppearanceTemplatesFailure,
  AppearanceTemplatesPending,
  AppearanceTemplatesSuccess
} from "../actions/appearanceTypes";


const initialState = {
  templates: [],
  isLoading: false,
  error: null,
};

const appearanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case AppearanceTemplatesPending:
      return { ...state, isLoading: true, error: null };
    case AppearanceTemplatesSuccess:
      return { ...state, templates: transformPayload(action.payload.templates), isLoading: false };
    case AppearanceTemplatesFailure:
      return { ...state, isLoading: false, error: action.payload.templates };
    default:
      return state;
  }
};

export default appearanceReducer;
