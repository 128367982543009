import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form';
import {createMultilanguageReducer} from 'redux-multilanguage';
import appearanceReducer from "./appearanceReducer";
import companyReducer from './companyReducer';
import configReducer from "./configReducer";
import firebaseAuthReducer from "./firebaseAuthReducer";
import linksReducer from './linksReducer';
import linkUserReducer from "./linkUserReducer";
import mediaContentReducer from "./mediaContentReducer";
import storeReducer from "./storeReducer";

const appReducer = combineReducers({
  multilanguage: createMultilanguageReducer({currentLanguageCode: 'en'}),
  form: formReducer.plugin({
    account: (state, action) => { // <------ 'account' is name of form given to reduxForm()
      switch (action.type) {
        // case ACCOUNT_SAVE_SUCCESS:
        //   return undefined;       // <--- blow away form data
        default:
          return state;
      }
    },
  }),
  company: companyReducer,
  links: linksReducer,
  firebaseAuth: firebaseAuthReducer,
  config: configReducer,
  store: storeReducer,
  appearance: appearanceReducer,
  linksUsers: linkUserReducer,
  mediaContentReducer:mediaContentReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
