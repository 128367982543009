import 'bootstrap/dist/css/bootstrap.min.css';
import React, {lazy, Suspense} from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

// Css Import
import ScrollToTop from './component/scrollToTop/ScrollToTop';
import AuthListener from "./helpers/AuthListener";

const ErrorPage = lazy(() => import('./pages/404'));
const AboutUs = lazy(() => import('./pages/AboutUs'));
const BlogArchive = lazy(() => import('./pages/Archive'));
const BlogDetails = lazy(() => import('./pages/BlogDetails'));
const BlogGridView = lazy(() => import('./pages/BlogGrid'));
const CareersDetails = lazy(() => import('./pages/CareersDetails'));
const CaseStudy = lazy(() => import('./pages/CaseStudy'));
const CaseDetails = lazy(() => import('./pages/CaseStudyDetails'));
const BlogCategory = lazy(() => import('./pages/Category'));
const ComingSoon = lazy(() => import('./pages/ComingSoon'));
const Contact = lazy(() => import('./pages/Contact'));
const CorporateAgency = lazy(() => import('./pages/CorporateAgency'));
const CreativeAgency = lazy(() => import('./pages/CreativeAgency'));
const DigitalAgency = lazy(() => import('./pages/DigitalAgency'));
const DynamicMenu = lazy(() => import('./pages/DynamicMenu/DynamicMenu'));
const HomeStartup = lazy(() => import('./pages/HomeStartup'));
const MainAnasayfa = lazy(() => import('./pages/MainAnasayfa'));
const MainPdf = lazy(() => import('./pages/MainPdf'));
const MainPdfMenu = lazy(() => import('./pages/MainPdfMenu/MainPdfMenu'));
const OurClients = lazy(() => import('./pages/OurClients'));
const OurOffice = lazy(() => import('./pages/OurOffice'));
const PersonalPortfolio = lazy(() => import('./pages/PersonalPortfolio'));
const PricingTable = lazy(() => import('./pages/PricingTable'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const ProjectDetails = lazy(() => import('./pages/ProjectDetails'));
const ProjectGridFive = lazy(() => import('./pages/ProjectGridFive'));
const ProjectGridFour = lazy(() => import('./pages/ProjectGridFour'));
const ProjectGridOne = lazy(() => import('./pages/ProjectGridOne'));
const ProjectGridThree = lazy(() => import('./pages/ProjectGridThree'));
const ProjectGridTwo = lazy(() => import('./pages/ProjectGridTwo'));
const ServiceDetails = lazy(() => import('./pages/ServiceDetails'));
const ServiceOne = lazy(() => import('./pages/ServiceOne'));
const ServiceTwo = lazy(() => import('./pages/ServiceTwo'));
const Team = lazy(() => import('./pages/Team'));
const TeamDetails = lazy(() => import('./pages/TeamDetails'));
const TermsOfUse = lazy(() => import('./pages/TermsOfUse'));
const Testimonials = lazy(() => import('./pages/Testimonials'));
const TypographyPage = lazy(() => import('./pages/Typography')); // Renamed to avoid conflict with the earlier Typography
const Linktree = lazy(() => import('./pages/LinktreeMenu/Linktree'));
function Urls() {


  return (
    <Router>
      <AuthListener />
      <ScrollToTop>
        <Suspense
          fallback={
            <div className="">
              <div className="">
                <span></span>
                <span></span>
              </div>
            </div>
          }
        >

          <Routes>
            <Route path={process.env.PUBLIC_URL + "/"} element={<DigitalAgency/>}/>
            <Route path={process.env.PUBLIC_URL + "/redirect"} element={<MainAnasayfa/>}/>
            <Route path={process.env.PUBLIC_URL + "/pdf"} element={<MainPdf/>}/>
            <Route path={process.env.PUBLIC_URL + "/links"} element={<Linktree/>}/>
            <Route
              exact
              path={process.env.PUBLIC_URL + '/pdf-menu/:id'}
              element={<MainPdfMenu/>}

            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/menu'}
              element={<DynamicMenu/>}
            />

            <Route path={process.env.PUBLIC_URL + "/sme-solutions"} element={<DigitalAgency/>}/>
            <Route path={process.env.PUBLIC_URL + "/creative-agency"} element={<CreativeAgency/>}/>
            <Route path={process.env.PUBLIC_URL + "/personal-portfolio"} element={<PersonalPortfolio/>}/>
            <Route path={process.env.PUBLIC_URL + "/home-startup"} element={<HomeStartup/>}/>
            <Route path={process.env.PUBLIC_URL + "/corporate-agency"} element={<CorporateAgency/>}/>

            {/* Blogs */}
            <Route path={process.env.PUBLIC_URL + "/blog-grid/"} element={<BlogGridView/>}/>
            <Route path={process.env.PUBLIC_URL + "/category/:slug"} element={<BlogCategory/>}/>
            <Route path={process.env.PUBLIC_URL + "/archive/:slug"} element={<BlogArchive/>}/>
            <Route path={process.env.PUBLIC_URL + "/blog-details/:id"} element={<BlogDetails/>}/>

            {/* Service */}
            <Route path={process.env.PUBLIC_URL + "/service-one/"} element={<ServiceOne/>}/>
            <Route path={process.env.PUBLIC_URL + "/service-two/"} element={<ServiceTwo/>}/>
            <Route path={process.env.PUBLIC_URL + "/service-details/:slug"} element={<ServiceDetails/>}/>

            {/* Project  */}
            <Route path={process.env.PUBLIC_URL + "/project-grid-one"} element={<ProjectGridOne/>}/>
            <Route path={process.env.PUBLIC_URL + "/project-grid-two"} element={<ProjectGridTwo/>}/>
            <Route path={process.env.PUBLIC_URL + "/careers"} element={<ProjectGridThree/>}/>
            <Route path={process.env.PUBLIC_URL + "/project-width-one"} element={<ProjectGridFour/>}/>
            <Route path={process.env.PUBLIC_URL + "/project-width-two"} element={<ProjectGridFive/>}/>
            <Route path={process.env.PUBLIC_URL + "/project-details/:slug"} element={<ProjectDetails/>}/>
            <Route path={process.env.PUBLIC_URL + "/careers/:slug"} element={<CareersDetails/>}/>

            {/* Pages  */}
            <Route path={process.env.PUBLIC_URL + "/about-us"} element={<AboutUs/>}/>
            <Route path={process.env.PUBLIC_URL + "/our-office"} element={<OurOffice/>}/>
            <Route path={process.env.PUBLIC_URL + "/our-clients"} element={<OurClients/>}/>
            <Route path={process.env.PUBLIC_URL + "/team"} element={<Team/>}/>
            <Route path={process.env.PUBLIC_URL + "/team-details/:slug"} element={<TeamDetails/>}/>
            <Route path={process.env.PUBLIC_URL + "/case-study"} element={<CaseStudy/>}/>
            <Route path={process.env.PUBLIC_URL + "/case-details/:slug"} element={<CaseDetails/>}/>
            <Route path={process.env.PUBLIC_URL + "/testimonials"} element={<Testimonials/>}/>
            <Route path={process.env.PUBLIC_URL + "/pricing-table"} element={<PricingTable/>}/>
            <Route path={process.env.PUBLIC_URL + "/typography"} element={<TypographyPage/>}/>
            <Route path={process.env.PUBLIC_URL + "/contact"} element={<Contact/>}/>
            <Route path={process.env.PUBLIC_URL + "/404"} element={<ErrorPage/>}/>
            <Route path={process.env.PUBLIC_URL + "/coming-soon"} element={<ComingSoon/>}/>
            <Route path={process.env.PUBLIC_URL + "/privacy-policy"} element={<PrivacyPolicy/>}/>
            <Route path={process.env.PUBLIC_URL + "/terms-use"} element={<TermsOfUse/>}/>

          </Routes>
        </Suspense>
      </ScrollToTop>
    </Router>
  );
}

export default Urls;
