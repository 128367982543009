import {createUserWithEmailAndPassword, signOut} from 'firebase/auth';
import {toast} from "react-toastify";
import {auth} from "../../firebase";
import {addOrUpdateQueryParam, getRelativeUrl} from "../../helpers/urlHelper";
import {
  AUTH_FAIL,
  AUTH_LOGOUT,
  AUTH_START,
  AUTH_SUCCESS,
  CREATE_AUTH_USER_FAIL,
  CREATE_AUTH_USER_START,
  CREATE_AUTH_USER_SUCCESS
} from "./firebaseAuthTypes";

// Action Creators
export const authStart = () => (
  {type: AUTH_START}
);

export const authSuccess = (userCredential, user, idTokenResult) => {
  storeSessionData(user, userCredential, idTokenResult);
  return {
    type: AUTH_SUCCESS,
    idToken: user.accessToken,
    user,
    userCredential,
    idTokenResult,
    adminDeveloper: idTokenResult.claims?.['admin_developer'] === true,
    premiumUser: idTokenResult.claims?.['stripeRole'] === 'premium',
    trialUser: idTokenResult.claims?.['stripeRole'] === 'trial',
    trialStartAt: idTokenResult.claims?.['trial_start_at'],
    trialEndAt: idTokenResult.claims?.['trial_end_at'],
    isTrialEnded: idTokenResult.claims?.['stripeRole'] === 'trial_ended',
  };
};

export const authFail = (error) => (
  {
    type: AUTH_FAIL,
    error
  }
);

export const authLogout = (navigate, currentPath) => {
  return async (dispatch) => {
    try {
      // Sign out of Firebase
      await signOut(auth);

      // Clear session data
      clearSessionData();

      const returnUrl = window.location.href; // Use full URL for flexibility
      let finalUrl = returnUrl; // Default to current URL if no modifications are needed

      // Update the URL based on `currentPath` values
      if (currentPath?.links) {
        finalUrl = getRelativeUrl(addOrUpdateQueryParam(finalUrl, 'links', currentPath.links));
      }

      // if (currentPath?.['logged-in'] === true) {
      //   finalUrl = getRelativeUrl(addOrUpdateQueryParam(finalUrl, 'logged-in', false));
      // }

      // Ensure navigation only if the final URL differs from the current one
      if (new URL(finalUrl, window.location.origin).href !== window.location.href) {
        navigate(finalUrl);
      }


      // Dispatch logout action
      dispatch({type: AUTH_LOGOUT});
    }
    catch (error) {
      console.error('Error during sign-out process:', error);
    }
  };
};

export const createAuthUserStart = () => (
  {type: CREATE_AUTH_USER_START}
);

export const createAuthUserSuccess = (userCredential, user, idTokenResult) => {
  storeSessionData(user, userCredential, idTokenResult);
  return {
    type: CREATE_AUTH_USER_SUCCESS,
    idToken: user.accessToken,
    user,
    userCredential,
    idTokenResult,
    adminDeveloper: idTokenResult.claims?.['admin_developer'] === true,
    premiumUser: idTokenResult.claims?.['stripeRole'] === 'premium',
    trialUser: idTokenResult.claims?.['stripeRole'] === 'trial',
    trialStartAt: idTokenResult.claims?.['trial_start_at'],
    trialEndAt: idTokenResult.claims?.['trial_end_at'],
    isTrialEnded: idTokenResult.claims?.['stripeRole'] === 'trial_ended',
  };
};

export const createAuthUserFail = (error) => (
  {
    type: CREATE_AUTH_USER_FAIL,
    error
  }
);

// Utility Functions
const storeSessionData = (user, userCredential, idTokenResult) => {
  sessionStorage.setItem('RefreshToken', user.refreshToken);
  sessionStorage.setItem('RefreshToken', userCredential?._tokenResponse?.refreshToken);
  sessionStorage.setItem('AuthToken', user?.accessToken);
  sessionStorage.setItem('idTokenResult', JSON.stringify(idTokenResult));
  sessionStorage.setItem('claims', idTokenResult.claims?.['admin_developer']);
  // TODO: Add expiration date handling
};


const clearSessionData = () => {
  sessionStorage.removeItem('RefreshToken');
  sessionStorage.removeItem('AuthToken');
  sessionStorage.removeItem('idTokenResult');
  sessionStorage.removeItem('claims');
};


export const logOutFromFirebase = (history, currentPath) => async (dispatch) => {
  dispatch(authLogout(history, currentPath));
};

export const createAuthUser = (email, password, history) => async (dispatch) => {
  dispatch(createAuthUserStart());
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    toast.success("Account created successfully");
    const idTokenResult = await user.getIdTokenResult();
    dispatch(createAuthUserSuccess(userCredential, user, idTokenResult));
    history.push("/");
  }
  catch (error) {
    toast.error(error.message);
    dispatch(createAuthUserFail(error));
  }
};
