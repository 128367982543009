import {useEffect, useState} from 'react';

export const useSubscriptionParameter = (auth) => {
  const [queryParams, setQueryParams] = useState(() => {
    const params = new URLSearchParams(window.location.search);
    const queryObj = {};
    for (const [key, value] of params.entries()) {
      queryObj[key] = value;
    }
    return queryObj;
  });

  useEffect(() => {
    const updateQueryParams = async () => {
      const params = new URLSearchParams(window.location.search);

      if (params.has('subscription') && params.get('subscription') === 'true') {
        params.delete('subscription');
        const newUrl = `${window.location.pathname}?${params.toString()}${window.location.hash}`;
        window.history.replaceState({}, '', newUrl);

        // Unsubscribe from Firebase authentication
        const unsubscribe = auth.onAuthStateChanged((authUser) => {

            if (authUser !== 'undefined') {

                authUser.getIdToken(true).then(idToken => {
                    console.log('___idToken', idToken);
                    // console.log('auth
                });
            }
        });


        // Clean up the listener on unmount
        return () => unsubscribe();
        // const auth = getAuth();
        // try {
        //   await signOut(auth);
        // } catch (error) {
        //   console.error('Error signing out:', error);
        // }
      }

      // Update state
      const queryObj = {};
      for (const [key, value] of params.entries()) {
        queryObj[key] = value;
      }
      setQueryParams(queryObj);
    };

    updateQueryParams();

    window.addEventListener('popstate', updateQueryParams);
    return () => {
      window.removeEventListener('popstate', updateQueryParams);
    };
  }, []);

  return queryParams;
};
