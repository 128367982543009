import {transformPayload} from "../../helpers/transformPayload";
import {
  LINK_USERS_FETCH_ERROR,
  LINK_USERS_FETCH_LOADING,
  LINK_USERS_FETCH_RESET,
  LINK_USERS_FETCH_SUCCESS
} from '../actions/linkUserActionTypes';

const initialState = {
  users: [],
  error: null,
  loading: false
}

const linkUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case LINK_USERS_FETCH_SUCCESS:
      return {
        ...state,
        users: transformPayload(action.payload.users),
        loading: false,
        error: null
      };
    case LINK_USERS_FETCH_ERROR:
      return {
        ...state,
        users: [],
        loading: false,
        error: action.payload
      };
    case LINK_USERS_FETCH_LOADING:
      return {
        ...state,
        loading: true,
        error: null
      };
    case LINK_USERS_FETCH_RESET:
      return {
        ...state,
        users: [],
        loading: false,
        error: null
      };
    default:
      return state;
  }
}

export default linkUserReducer;