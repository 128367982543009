import {transformPayload} from "../../helpers/transformPayload";
import {
  UPLOAD_MEDIA_CONTENT_FAIL,
  UPLOAD_MEDIA_CONTENT_PROGRESS,
  UPLOAD_MEDIA_CONTENT_START,
  UPLOAD_MEDIA_CONTENT_SUCCESS,
} from '../actions/mediaContentTypes';

const initialState = {
  error: null,
  progress: 0,
  loading: false
}

const mediaContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_MEDIA_CONTENT_START:
      return {
        ...state,
        loading: true,
        error: null,
        progress: 0
      };
    case UPLOAD_MEDIA_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        progress: 100
      };
    case UPLOAD_MEDIA_CONTENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        progress: 0
      };
    case UPLOAD_MEDIA_CONTENT_PROGRESS:
      return {
        ...state,
        progress: action.payload
      };
    default:
      return state;
  }
}

export default mediaContentReducer;

