import {
  LINKS_FETCH_FAILURE,
  LINKS_FETCH_SUCCESS,
  LINKS_OWNER_DOES_NOT_EXIST,
  LINKS_OWNER_EXISTS,
  LINKS_UPDATE_FAILURE,
  LINKS_UPDATE_SUCCESS
} from '../actions/linksActionTypes';

const initialState = {
  id: "J3RX2RQDS",
  links: [
    {
      id: "7",
      image: "https://www.google.com/search?q=Tabula&stick=H4sIAAAAAAAA_-NgU1I1qLCwMEpLMki2SDM2TDM2skyxMqhItrRINEg0MkkyMbQwNTBNW8TKFpKYVJqTCACyHZLBMgAAAA&hl=en&mat=CfSHl9ACWV9ZElcBEKoLaSvQ_7w0eS6tsG49AlvjoPsGes8uru",
      linkAddress: "https://g.page/r/CV9QGLQkCorJEAI/review",
      order: 1,
      name: "Google Review",
      description: "link_description",
      plan: "8b1t5blwh",
      type: "basic_link",
      clicks: 3,
      socialLinkType: null,
      isActive: true
    },
    {
      id: "6",
      image: "https://calendly.com/contact-deland/30min",
      linkAddress: "https://calendly.com/contact-deland/30min",
      order: 2,
      name: "book a meeting",
      description: "",
      type: "basic_link",
      plan: "8b1t5blwh",
      clicks: 0,
      socialLinkType: null,
      isActive: true
    },
    {
      id: "8",
      image: "https://imange.com",
      linkAddress: "https://imange.com",
      order: 1,
      name: "Special Offers",
      description: "linkkk",
      plan: "8b1t5blwh",
      clicks: null,
      socialLinkType: null,
      isActive: true
    },
    {
      id: "UBQFQA63R",
      image: "https://tabula.la",
      linkAddress: "https://tabula.la",
      order: 3,
      name: "Special Offers",
      description: "<string>",
      plan: "8b1t5blwh",
      clicks: null,
      socialLinkType: null,
      isActive: true
    }
  ],
  activeSubscription: false,
  connectedTable: {
    id: "83l75y60s",
    storeId: "6275547av",
    number: 1,
    qrCode: "https:qrcode",
    status: "available"
  },
  directRedirect: true,
  directRedirectLink: null,
  pdfLinks: [
    {
      id: "dzc3cehu0",
      name: "PDF Menu",
      pdf: "https://tabula.la/pdf-menu/3nkx633jy",
      description: "pdf link",
      order: 1,
      clicks: null,
      socialLinkType: null,
      isActive: true
    }
  ],
  plan: "8b1t5blwh",
  planDetails: {
    "id": "epjmv9r5r",
    "isTrial": false,
    "isEnabled": true,
    "trialStartedAt": "2025-01-18T16:19:34.601832Z",
    "usageStartedAt": "2025-01-18T16:19:34.601832Z",
    "usageEndAt": "2026-01-18T16:19:34.601840Z"
  },
  socialLinks: [
    {
      id: "BIX8F7UQU",
      image: "https://twitter.com",
      linkAddress: "https://twitter.com",
      order: 1,
      name: "twitter",
      description: "twitter link",
      plan: "8b1t5blwh",
      social_link_type: "twitter",
      clicks: null,
      socialLinkType: "twitter",
      isActive: true
    },
    {
      id: "KZ2T7BFMD",
      image: "https://www.instagram.com",
      linkAddress: "https://www.instagram.com",
      order: 1,
      name: "instagram",
      description: "instagram link",
      plan: "8b1t5blwh",
      social_link_type: "instagram",
      clicks: 3,
      socialLinkType: "instagram",
      isActive: true
    }
  ],
  backgroundImage: "https://tabula-app-bucket.s3.amazonaws.com/static/links/background/images/J3RX2RQDS_background_UT26AUT.jpg",
  logoImage: "https://tabula-app-bucket.s3.amazonaws.com/static/links/logo/images/J3RX2RQDS_logo_PDcRGiK.jpg",
  textColor: "#ffffff",
  linkOwnerExists: true,
  socialMediaUploadLinks: [
    {
      id: "VQEWI7QL2",
      image: null,
      linkAddress: "",
      order: 4,
      name: "Collaborate With Us",
      description: null,
      plan: "8b1t5blwh",
      socialLinkType: null,
      type: "social_media_upload_link",
      isActive: true,
      clicks: 0,
      signedUpAccountsOnly: false,
      linkStartActiveTime: null,
      linkEndActiveTime: null,
      isStartedLink: false,
      functionalLink: true
    }
  ]
};

export function isTrialClient(planDetails) {
  return planDetails?.isTrial === true;
}

export function isActivatedClient(planDetails) {
  return planDetails?.isEnabled === true;
}

export function trialEndsAt(planDetails) {
  return planDetails?.trialEndsAt;
}

export function activePlanId(planDetails) {
  return planDetails?.id;
}

const transformPayload = (payload) => {
  const transformKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map(item => transformKeys(item));
    }
    else if (obj !== null && typeof obj === 'object') {
      return Object.keys(obj).reduce((acc, key) => {
        const camelCaseKey = key.replace(/_([a-z])/g, g => g[1].toUpperCase());
        acc[camelCaseKey] = transformKeys(obj[key]);
        return acc;
      }, {});
    }
    return obj;
  };

  return transformKeys(payload);
};

const linksReducer = (state = initialState, action) => {
  switch (action.type) {
    case LINKS_FETCH_SUCCESS:
      return {
        ...state,
        ...transformPayload(action.payload),
        linkOwnerExists: true,
        error: null,
      };
    case LINKS_FETCH_FAILURE:
      return {
        ...initialState,
        error: action.payload.error,
      };
    case LINKS_UPDATE_SUCCESS:
      return {
        ...state,
        ...transformPayload(action.payload),
        error: null,
      };
    case LINKS_UPDATE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    case LINKS_OWNER_DOES_NOT_EXIST:
      return {
        ...initialState,
        linkOwnerExists: false,
      };
    case LINKS_OWNER_EXISTS:
      return {
        ...state,
        linkOwnerExists: true,
      };
    default:
      return state;
  }
};

export default linksReducer;

export const getLinks = state => state.linksReducer;
