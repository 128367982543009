import {
  CONFIG_FETCH_FAILURE,
  CONFIG_FETCH_LOADING,
  CONFIG_FETCH_LOADING_FINISHED,
  CONFIG_FETCH_REQUEST,
  CONFIG_FETCH_SUCCESS,
  CONFIG_UPDATE_FAILURE,
  CONFIG_UPDATE_REQUEST,
  CONFIG_UPDATE_SUCCESS,
  UPDATE_STRIPE_ROLE
} from "../actions/configActionTypes";

// Utility function to transform keys from snake_case to camelCase
const transformKeysToCamelCase = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(item => transformKeysToCamelCase(item));
  } else if (obj !== null && typeof obj === "object") {
    return Object.keys(obj).reduce((acc, key) => {
      const camelCaseKey = key.replace(/_([a-z])/g, g => g[1].toUpperCase());
      acc[camelCaseKey] = transformKeysToCamelCase(obj[key]);
      return acc;
    }, {});
  }
  return obj;
};

// Utility function to transform keys from camelCase to snake_case
const transformKeysToSnakeCase = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(item => transformKeysToSnakeCase(item));
  } else if (obj !== null && typeof obj === "object") {
    return Object.keys(obj).reduce((acc, key) => {
      const snakeCaseKey = key.replace(/([A-Z])/g, "_$1").toLowerCase();
      acc[snakeCaseKey] = transformKeysToSnakeCase(obj[key]);
      return acc;
    }, {});
  }
  return obj;
};

// Initial state
const initialState = {
  plans: [],
  user: {},
  permissions: [],
  linkIds: [],
  error: null,
  loading: false, // Add loading state
  stripeRole: null,
};

// Utility function to check permissions
export function hasPermission(permissions, permission) {
  return permissions.includes(permission);
}

// Utility function to check link_ids
export function hasLink(link_ids, linkId) {
  return link_ids.includes(linkId);
}

// Reducer
const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONFIG_FETCH_REQUEST:
      return {
        ...state,
        // loading: true,
      };
    case CONFIG_UPDATE_REQUEST:
      return {
        ...state,
        // loading: true, // Set loading to true when the fetch or update request starts
      };
    case CONFIG_FETCH_SUCCESS:
      return {
        ...state,
        plans: action.payload.user?.plans
          ? transformKeysToCamelCase(action.payload.user.plans)
          : [],
        user: transformKeysToCamelCase(action.payload.user) || {},
        permissions: transformKeysToCamelCase(action.payload.user?.permissions) || [],
        linkIds: transformKeysToCamelCase(action.payload.user?.link_ids) || [],
        error: null,
        
        // loading: false, // Set loading to false when the fetch is successful
      };
    case CONFIG_FETCH_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        // loading: false, // Set loading to false when the fetch fails
      };
    case CONFIG_UPDATE_SUCCESS:
      return {
        ...state,
        plans: action.payload.user?.plans
          ? transformKeysToCamelCase(action.payload.user.plans)
          : [],
        user: transformKeysToCamelCase(action.payload.user) || {},
        permissions: transformKeysToCamelCase(action.payload.user?.permissions) || [],
        linkIds: transformKeysToCamelCase(action.payload.user?.link_ids) || [],
        error: null,
        // loading: false, // Set loading to false when the update is successful
      };
    case CONFIG_UPDATE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        // loading: false, // Set loading to false when the update fails
      };
    case CONFIG_FETCH_LOADING:
      return {
        ...state,
        loading: true, // Set loading to true when the fetch starts
      };
    case CONFIG_FETCH_LOADING_FINISHED:
      return {
        ...state,
        loading: false, // Set loading to false when the fetch finishes
      };
    case UPDATE_STRIPE_ROLE:
      return {
        ...state,
        stripeRole: action.payload.stripeRole,
      };
    default:
      return state;
  }
};

// Export functions to transform payloads before sending
export const transformPayloadToSend = (payload) => {
  return transformKeysToSnakeCase(payload);
};

export default configReducer;

