import {getAuth, onAuthStateChanged} from 'firebase/auth';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from "react-router-dom";
import {app} from '../firebase';
import {authLogout, authSuccess} from '../redux/actions/firebaseAuthActions';
import {useQueryParams} from "./useQueryParams";
import {useSubscriptionParameter} from "./useSubscriptionParameter"; // Import your Firebase configuration

const AuthListener = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = getAuth(app);
  const queryParams = useQueryParams(); // Ensure this is imported correctly
  const queyParamsStripe = useSubscriptionParameter(auth);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const idTokenResult = await user.getIdTokenResult();

          // Mimic userCredential structure
          const userCredential = {
            user,
            _tokenResponse: {
              refreshToken: user.refreshToken, // Assume refreshToken exists on user
            },
          };

          // Dispatch success action with userCredential and token details
          dispatch(authSuccess(userCredential, user, idTokenResult));
        } catch (error) {
          console.error('Error getting ID token result:', error);
        }
      } else {
        // Dispatch logout action if no user is signed in
        await dispatch(authLogout(navigate, queryParams));
      }
    });

    return () => unsubscribe(); // Clean up the listener on component unmount
  }, [auth, queryParams]);

  return null; // Component does not render anything
};

export default AuthListener;