import {ThemeProvider} from '@mui/material/styles';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from "prop-types";
import React, {useEffect} from 'react';

// Analytics
import ReactGA from "react-ga4";
import {connect} from "react-redux";
import {ToastContainer} from 'react-toastify';
// Css Import
import './assets/scss/app.scss';
import {loadLanguages, multilanguage} from 'redux-multilanguage';
import CSRFToken from "./helpers/CSRFToken";
import {useQueryParams} from "./helpers/useQueryParams";

import {theme} from './theme';
import Urls from "./Urls";
import prerender from './utils/prerender';

// Initialize ReactGA
ReactGA.initialize('G-W4CYLRR8E6');
ReactGA.send(window.location.pathname);

const repoPrompt = `
__  __  __
           .,-:;//;:=,
        . :H@@@MM@M#H/.,+%;,
     ,/X+ +M@@M@MM%=,-%HMMM@X/,
    -+@MM; $M@@MH+-,;XMMMM@MMMM@+-
   ;@M@@M- XM@X;. -+XXXXXHHH@M@M#@/.
 ,%MM@@MH ,@%=            .---=-=:=,.
 -@#@@@MX .,              -%HX$$%%%+;
=-./@M@M$                  .;@MMMM@MM:
X@/ -$MM/                    .+MM@@@M$
,@M@H: :@:                    . -X#@@@@-
,@@@MMX, .                    /H- ;@M@M=
.H@@@@M@+,                    %MM+..%#$.
/MMMM@MMH/.                  XM@MH; -;
 /%+%$XHH@$=              , .H@@@@MX,
  .=--------.           -%H.,@@@@@MX,
  .%MM@@@HHHXX$$$%+- .:$MMX -M@@MM%.
    =XMMM@MM@MM#H;,-+HMM@M+ /MMMX=
      =%@M@M#@$-.=$@MM@@@M; %M%=
        ,:+$+-,/H#MMMMMMM@- -,
              =++%%%%+/:-.
__  __  __
\n\nTaking a peek huh? Check out the source code: https://github.com/delandcaglar
\n\nFor more information, visit: https://delandcaglar.com
`;

const App = (props) => {
  // Effect for initialization
  const queryParams = useQueryParams();
  useEffect(() => {
    if (!prerender) {
      console.info(`${repoPrompt}\n\n`);
    }
    window.history.scrollRestoration = 'manual';
  }, []);

  // Effect for loading languages
  useEffect(() => {
    props.dispatch(
      loadLanguages({
        languages: {
          en: require('./translations/english.json'),
          fn: require('./translations/french.json'),
          de: require('./translations/germany.json'),
          tr: require('./translations/turkish.json'),
        },
      }),
    );
  }, [props.dispatch]); // Adding dispatch to the dependency array

  return (
    <ThemeProvider theme={theme}>
      <Urls/>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <CSRFToken />
    </ThemeProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(multilanguage(App));
