import {createTheme} from '@mui/material/styles';


// const breakpoints = {
//   values: {
//     xs: 0,
//     sm: 600,
//     md: 905,
//     lg: 1240,
//     xl: 1440,
//   },
// };


export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 905,
      lg: 1240,
      xl: 1440,
    },
  },

  palette: {
    primary: {
      main: '#ffffff',
      contrastText: '#000000',
      dark: '#f0f0f0',
      light: '#ffffff',
    },
    secondary: {
      main: '#444444',
      contrastText: '#ffffff',
      dark: '#222222',
      light: '#666666',
    },
    background: {
      default: '#F3F3F1',
      paper: '#ffffff',
    },
    text: {
      primary: '#000000',
      secondary: '#444444',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
    customPurple: {
      main: '#8129D9',
      contrastText: 'white',
      dark: '#680fd4'
    },
    customGreen: {
      main: '#11cb5f',
      contrastText: 'white',
      dark: '#0daa4f'
    },
    fqRed: {
      main: '#F85467',
      contrastText: 'white',
    },
    menuMainPrimary: {
      main: '#32292F',
      contrastText: 'white',
    },
    menuMainSecondary: {
      main: '#32292F',
      contrastText: 'white',
    },
    menuMainTertiary: {
      main: '#32292F',
      contrastText: 'white',
    },
  },
  myButton: {
    backgroundColor: '#11cb5f',
    color: '#f31111',
    fontSize: '1rem',
    fontWeight: 'bold',
    padding: '0.5rem 1rem',
    border: 'none',
  },
  logoLg: {
    height: '3rem',
    width: 'auto',
  },
  logoSm: {
    height: '1.5rem',
    width: 'auto',
  },
  typography: {
    h1: {
      // fontSize: '13rem',
      [`@media screen and (min-width: ${0}px)`]: {
        fontFamily: 'Respondent',
        fontStyle: 'italic',
        fontSize: '30px',
        fontWeight: 300,
        lineHeight: '38.1px',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
      [`@media screen and (min-width: ${600}px)`]: {
        fontFamily: 'Respondent',
        fontStyle: 'italic',
        fontSize: '50px',
        fontWeight: 300,
        lineHeight: '55px',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
      [`@media screen and (min-width: ${905}px)`]: {
        fontFamily: 'Respondent',
        fontStyle: 'italic',
        fontSize: '60px',
        fontWeight: 300,
        lineHeight: '76.2px',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
      [`@media screen and (min-width: ${1240}px)`]: {
        fontFamily: 'Respondent',
        fontStyle: 'italic',
        fontSize: '70px',
        fontWeight: 300,
        lineHeight: '88.9px',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
      [`@media screen and (min-width: ${1440}px)`]: {
        fontFamily: 'Respondent',
        fontStyle: 'italic',
        fontSize: '80px',
        fontWeight: 300,
        lineHeight: '101.6px',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
    },
    h2: {
      // fontSize: '13rem',
      [`@media screen and (min-width: ${0}px)`]: {
        fontFamily: 'Khand',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24.46px',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
      [`@media screen and (min-width: ${600}px)`]: {
        fontFamily: 'Khand',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '27.52px',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
      [`@media screen and (min-width: ${905}px)`]: {
        fontFamily: 'Khand',
        fontWeight: 600,
        fontSize: '25px',
        lineHeight: '24px',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
      [`@media screen and (min-width: ${1240}px)`]: {
        fontFamily: 'Khand',
        fontWeight: 600,
        fontSize: '30px',
        lineHeight: '45.87px',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
      [`@media screen and (min-width: ${1440}px)`]: {
        fontFamily: 'Khand',
        fontWeight: 600,
        fontSize: '32px',
        lineHeight: '48.93px',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
    },
    h5: {
      fontFamily: 'Khand',
      fontWeight: 'bold',
      fontSize: '1.5rem',
      color: '#333',
      marginBottom: '2rem',
    },
    h1Plaj: {
      // fontSize: '13rem',
      [`@media screen and (min-width: ${0}px)`]: {
        fontFamily: 'Respondent',
        fontWeight: 300,
        fontStyle: 'italic',
        fontSize: '30px',
        lineHeight: '38.1px',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
      [`@media screen and (min-width: ${600}px)`]: {
        fontFamily: 'Respondent',
        fontWeight: 300,
        fontStyle: 'italic',
        fontSize: '30px',
        lineHeight: '33px',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
      [`@media screen and (min-width: ${905}px)`]: {
        fontFamily: 'Respondent',
        fontWeight: 300,
        fontStyle: 'italic',
        fontSize: '40px',
        lineHeight: '50.8px',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
      [`@media screen and (min-width: ${1240}px)`]: {
        fontFamily: 'Respondent',
        fontWeight: 300,
        fontStyle: 'italic',
        fontSize: '50px',
        lineHeight: '63.5px',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
      [`@media screen and (min-width: ${1440}px)`]: {
        fontFamily: 'Respondent',
        fontWeight: 300,
        fontStyle: 'italic',
        fontSize: '60px',
        lineHeight: '76.1px',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
    },
    t1: {
      // fontSize: '13rem',
      [`@media screen and (min-width: ${0}px)`]: {
        fontFamily: 'Khand',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
      [`@media screen and (min-width: ${600}px)`]: {
        fontFamily: 'Khand',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
      [`@media screen and (min-width: ${905}px)`]: {
        fontFamily: 'Khand',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '21px',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
      [`@media screen and (min-width: ${1240}px)`]: {
        fontFamily: 'Khand',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
      [`@media screen and (min-width: ${1440}px)`]: {
        fontFamily: 'Khand',
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '27px',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
    },
  },
  components: {
    // MuiGrid: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: '#ffffff',
    //     },
    //   },
    // },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: 'purple', // Change this to your desired color
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'black',
          },
        },
        notchedOutline: {
          borderColor: 'black',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#000000', // Override default color for IconButton
          '&:hover': {
            color: '#800080', // Set link color to purple on hover
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#000000', // Override default color for SvgIcon (applies to icons like EditIcon)
          '&:hover': {
            color: '#800080', // Set link color to purple on hover
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#000000', // Set link color to black
          textDecoration: 'none', // Remove underline
          '&:hover': {
            color: '#800080', // Set link color to purple on hover
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#800080',
          },
        },
        notchedOutline: {
          borderColor: '#800080',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          // General button styling
          borderRadius: '15px',
          '& .MuiSvgIcon-root': {
            color: 'inherit', // Inherit the color from the button
          },
          '&.Mui-disabled': { // Target disabled button
            backgroundColor: '#D3D3D3', // Set background color to gray
            color: '#ffffff', // Set text color to purple
          },
        },
      },
      variants: [
        {
          props: {variant: 'customPurple'},
          style: ({theme}) => (
            {
              backgroundColor: theme.palette.customPurple.main,
              color: theme.palette.customPurple.contrastText,
              '&:hover': {
                backgroundColor: theme.palette.customPurple.dark,
              },
              '& .MuiSvgIcon-root': {
                color: theme.palette.customPurple.contrastText,
              },
            }
          ),
        },
        {
          props: {variant: 'loginWithGoogle'},
          style: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'background-color .3s, box-shadow .3s',
            padding: '8px 16px',
            border: '2px solid #fff',
            borderRadius: '4px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            color: '#fff',
            fontSize: '16px',
            fontFamily: 'Karla, sans-serif',
            backgroundColor: 'transparent',
            backgroundImage: 'none',
            width: 'auto',
            maxWidth: '300px',
            minWidth: '64px',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              boxShadow: '0 6px 8px rgba(0, 0, 0, 0.15)',
            },
            '&:active': {
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
            },
            '&:focus': {
              outline: 'none',
              boxShadow: '0 0 0 3px rgba(255, 255, 255, 0.5)',
            },
            '&:disabled': {
              filter: 'grayscale(100%)',
              backgroundColor: '#ebebeb',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              cursor: 'not-allowed',
            },
            '& .login-with-google-icon': {
              marginRight: '8px',
              color: '#fff',
            },
            '& .MuiSvgIcon-root': {
              color: '#fff',
            },
          },
        },
      ]
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root': {
            color: '#800080', // Purple color for the label
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#800080', // Purple color for the focused label
          },
          '& .MuiInputLabel-root.Mui-error': {
            color: '#800080', // Purple color for the error label
          },
          '& .MuiOutlinedInput-root': {
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#800080', // Purple color for the border on hover
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#800080', // Purple color for the border when focused
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              borderColor: '#800080', // Purple color for the border when error
            },
          },
        },
      },
    },
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //       fontFamily: 'Khand',
    //       size: 'small',
    //       '& .MuiInputBase-input': {
    //         // Apply text selection styles
    //         '&::selection': {
    //           backgroundColor: '#800080', // Purple selection background
    //           color: 'white', // Color of the selected text
    //         },
    //         '&::-moz-selection': {
    //           backgroundColor: '#800080', // Purple selection background for Firefox
    //           color: 'white', // Color of the selected text
    //         },
    //       },
    //     },
    //   },
    // },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Khand';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Khand'), local('Khand'), url(/path/to/Khand.otf) format('otf');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        },
      `,
    },
    MuiFormControl: {
      defaultProps: {
        fontFamily: 'Khand',
        size: 'small',
      },
    },
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontFamily: 'Khand',
          fontSize: '13px',
          top: 2,
        },
      },
      styleOverrides: {
        shrink: ({ownerState}) => (
          {
            ...(
              ownerState.shrink && {
                fontFamily: 'Khand',
                fontSize: '1rem !important',
                top: '-1 !important',
              }
            ),
          }
        ),
      },
    },
  },
});

