export const transformPayload = (payload) => {
  const transformKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map(item => transformKeys(item));
    }
    else if (obj !== null && typeof obj === 'object') {
      return Object.keys(obj).reduce((acc, key) => {
        const camelCaseKey = key.replace(/_([a-z])/g, g => g[1].toUpperCase());
        acc[camelCaseKey] = transformKeys(obj[key]);
        return acc;
      }, {});
    }
    return obj;
  };

  return transformKeys(payload);
};


// Utility function to convert camelCase to snake_case
const camelToSnakeCase = (str) => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

// Function to transform the payload's keys to snake_case
export const transformPayloadToSend = (payload) => {
  const transformKeys = (obj) => {
    if (obj instanceof FormData) {
      // Do not transform FormData keys
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map(item => transformKeys(item));
    }
    else if (obj !== null && typeof obj === 'object') {
      return Object.keys(obj).reduce((acc, key) => {
        const snakeCaseKey = camelToSnakeCase(key);
        acc[snakeCaseKey] = transformKeys(obj[key]);
        return acc;
      }, {});
    }
    return obj;
  };

  return transformKeys(payload);
};




