import {useEffect, useState} from 'react';

export const useQueryParams = () => {
  const [queryParams, setQueryParams] = useState(() => {
    const params = new URLSearchParams(window.location.search);
    const queryObj = {};
    for (const [key, value] of params.entries()) {
      queryObj[key] = value;
    }
    return queryObj;
  });

  useEffect(() => {
    const updateQueryParams = () => {
      const params = new URLSearchParams(window.location.search);
      const queryObj = {};
      for (const [key, value] of params.entries()) {
        queryObj[key] = value;
      }

      // Clean up query params
      const cleanedParams = new URLSearchParams();
      Object.entries(queryObj).forEach(([key, value]) => {
        cleanedParams.append(key, value);
      });

      const currentParams = new URLSearchParams(window.location.search);

      // Compare the current params with the cleaned ones
      const haveDifferentParams = Array.from(cleanedParams.entries()).some(
        ([key, value]) => currentParams.get(key) !== value
      );

      const haveDifferentLengths = currentParams.toString().length !== cleanedParams.toString().length;

      if (haveDifferentParams || haveDifferentLengths) {
        const newUrl = `${window.location.pathname}?${cleanedParams.toString()}${window.location.hash}`;
        window.history.replaceState({}, '', newUrl);
      }

      setQueryParams(queryObj);
    };

    // Update on initial load
    updateQueryParams();

    // Listen for changes to the URL
    window.addEventListener('popstate', updateQueryParams);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('popstate', updateQueryParams);
    };
  }, [window.location.search]);

  return queryParams;
};
