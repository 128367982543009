import {
  STORE_FETCH_FAILURE,
  STORE_FETCH_LOADING,
  STORE_FETCH_SUCCESS,
  STORE_UPDATE_FAILURE,
  STORE_UPDATE_SUCCESS
} from '../actions/storeActionTypes';

const initialState = {
  store: {
    'id': '',
    'name': '',
    'address': '',
    'phone': '',
    'email': '',
    'plan': '',
    'managerEmail': ''
  },
  loading: false,
  error: null
}


const transformPayload = (payload) => {
  const transformKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map(item => transformKeys(item));
    }
    else if (obj !== null && typeof obj === 'object') {
      return Object.keys(obj).reduce((acc, key) => {
        const camelCaseKey = key.replace(/_([a-z])/g, g => g[1].toUpperCase());
        acc[camelCaseKey] = transformKeys(obj[key]);
        return acc;
      }, {});
    }
    return obj;
  };

  return transformKeys(payload);
};


const storeReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_FETCH_SUCCESS:
      return {
        ...state,
        store: transformPayload(action.payload),
        loading: false,
        error: null,
      };
    case STORE_FETCH_FAILURE:
      return {
        ...state,
        store: initialState.store,
        loading: false,
        error: action.payload.error,
      };
    case STORE_UPDATE_SUCCESS:
      return {
        ...state,
        store: transformPayload(action.payload),
        loading: false,
        error: null,
      };
    case STORE_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case STORE_FETCH_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    default:
      return state;
  }
};

export default storeReducer;

export const getStore = state => state.storeReducer;